import React from 'react';
import styles from './style.module.scss';
import Link from 'next/link';
import { useMediaQuery } from '@react-hook/media-query';
import WsStaticImage from '@/components/common/WsStaticImage';

interface NearestStoreProps {
  // data: React.ReactNode[];
}

const NearestStore: React.FC<NearestStoreProps> = () => {
  const isMobile = useMediaQuery('only screen and (max-width: 767px)');
  const postersData = [
    {
      href: 'offline-furniture-store',
      ariaLabel: 'Visit Our Nearest Store',
      imgSrc: '/mobile/images-m/category-new/ws-store.png',
      alt: 'Buy Furniture Offline in India',
      title: 'Buy Furniture Offline',
    },
  ];

  return (
    <>
      {isMobile && (
        <section className={styles.neareststoresection}>
          <div className={styles.outdoorinner}>
            <div className={styles.outdoorcontent}>
              <div className="wdRow">
                {postersData.map((poster, index) => (
                  <div className="wdCol-lg-12" key={index}>
                    <Link
                      href={poster.href}
                      className={styles.outdoorcard}
                      aria-label={poster.ariaLabel}
                    >
                      <div className={styles.outdoorimg}>
                        <WsStaticImage
                          src={poster.imgSrc}
                          alt={poster.alt ?? 'poster'}
                          title={poster.title}
                          width={585}
                          height={508}
                          quality={75}
                          loading="lazy"
                        />
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default NearestStore;
