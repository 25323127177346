import React, { useState } from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import { Rating } from 'react-simple-star-rating';
import dynamic from 'next/dynamic';
import WsStaticImage from '@/components/common/WsStaticImage';
import NearestStore from '../nearest-store-mobile';
import OffersMobile from '../offers-mobile';
import CallUs from '../../home-bottom-call';
const WsModal = dynamic(() => import('@/components/common/WsModal'));

interface HappyWordsMobileProps {
  items: any;
}

interface CustomerReviewProps {
  review: any;
  onClick: (videoId: string) => void;
}

const Story: React.FC<CustomerReviewProps> = ({ review, onClick }) => {
  return (
    <>
      <div
        className={styles.happywordcard}
        onClick={() => onClick(review.video)}
      >
        <div className={styles.happywordimg}>
          <Image
            width={445}
            height={386}
            quality={75}
            src={review.image}
            alt={review?.alt}
            title={review?.title}
          />
        </div>
        <div className={styles.happywordtext}>
          <p> {review.testimonial}</p>
          <div className={styles.rating}>
            <Rating
              initialValue={Math.round(review.rating)}
              allowFraction={true}
              readonly
              size={23}
              fillColor={'#ee7120'}
              SVGstorkeWidth={1}
              SVGstrokeColor={'currentColor'}
              iconsCount={Math.round(review.rating)}
              emptyColor={'#D1D1D1'}
            />
          </div>
          <div className={styles['customer-name']}>
            {review.userInfo} <span>{review.city}</span>
          </div>
        </div>
      </div>
    </>
  );
};
const HappyWordsMobile: React.FC<HappyWordsMobileProps> = ({ items }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoId, setVideoId] = useState('');
  const handleOpenModal = (videoId: string) => {
    setIsModalOpen(true);
    setVideoId(videoId);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setVideoId('');
  };
  return (
    <>
      <section className={styles.custstoriemobsection}>
        <div className={styles.custstoriemobinner}>
          <div className="wdRow ">
            <div className="wdCol-lg-12">
              <div className={styles.custstoriemobheading}>
                <div>{items?.title}</div>
                <p>{items?.description}</p>
              </div>
            </div>
          </div>
          <div className={styles.custstoriemobcontent}>
            {items?.items?.map((review: any, index: number) => (
              <Story review={review} onClick={handleOpenModal} key={index} />
            ))}
          </div>

          <div className={styles.viewmorebtndiv}>
            <Link
              href={items.viewMore}
              className={`${styles['view-all-furni']} btn view-all-btn`}
            >
              View More
              <WsStaticImage
                src={'/images/home-new1/ArrowRight.svg'}
                alt="ArrowRight"
                width={24}
                height={20}
                quality={75}
                loading="lazy"
              />
            </Link>
          </div>
        </div>
      </section>
      <WsModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        mainClass="happy-customers-modal"
        size="small"
      >
        <iframe
          className={`${styles['happy-customer-video']} customer-video`}
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </WsModal>

      <NearestStore />
      <OffersMobile />
      <CallUs />
    </>
  );
};

export default HappyWordsMobile;
