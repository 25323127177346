import React, { useState, useEffect, useRef } from 'react';
import styles from './style.module.scss';
import Link from 'next/link';

interface CallUsProps {
  // data: React.ReactNode[];
}
const THRESHOLD = 0;
const CallUs: React.FC<CallUsProps> = () => {
  const [scrollDirection, setScrollDirection] = useState('up');

  const blocking = useRef(false);
  const prevScrollY = useRef(0);

  useEffect(() => {
    prevScrollY.current = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;

      if (Math.abs(scrollY - prevScrollY.current) >= THRESHOLD) {
        const newScrollDirection =
          scrollY > prevScrollY.current ? 'down' : 'up';

        setScrollDirection(newScrollDirection);

        prevScrollY.current = scrollY > 0 ? scrollY : 0;
      }

      blocking.current = false;
    };

    const onScroll = () => {
      if (!blocking.current) {
        blocking.current = true;
        window.requestAnimationFrame(updateScrollDirection);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDirection]);

  return (
    <>
      <section
        className={`${styles['footer-sale']} ${scrollDirection != 'up' ? styles['footer-sale-sticky'] : ''}`}
      >
        <Link
          href={
            'https://api.whatsapp.com/send?phone=919314444747&text=Hi,%20I%20would%20like%20to%20discuss%20about%20the%20furniture%20and%20other%20details.%20Kindly%20assist!'
          }
          target="_blank"
          className={styles['footer-list']}
        >
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.1146 13.6517C11.0222 15.5072 12.5264 17.0045 14.3861 17.9037C14.5221 17.9682 14.6726 17.9961 14.8228 17.9848C14.9729 17.9734 15.1175 17.9231 15.2423 17.8389L17.9806 16.013C18.1017 15.9322 18.241 15.883 18.386 15.8696C18.5309 15.8563 18.6769 15.8794 18.8107 15.9367L23.9335 18.1322C24.1075 18.2061 24.2528 18.3346 24.3475 18.4983C24.4421 18.6619 24.481 18.852 24.4583 19.0397C24.2964 20.3067 23.6782 21.4712 22.7195 22.3152C21.7608 23.1593 20.5273 23.6249 19.25 23.625C15.3049 23.625 11.5214 22.0578 8.73179 19.2682C5.94218 16.4786 4.375 12.6951 4.375 8.74999C4.37507 7.47268 4.84071 6.23919 5.68475 5.28047C6.52878 4.32176 7.69332 3.70357 8.96033 3.54166C9.14803 3.51894 9.33803 3.55784 9.5017 3.6525C9.66538 3.74716 9.79385 3.89244 9.86778 4.06646L12.0652 9.19373C12.122 9.32635 12.1452 9.47097 12.1326 9.61471C12.12 9.75845 12.0721 9.89684 11.9931 10.0176L10.1735 12.7979C10.0906 12.9229 10.0417 13.0673 10.0314 13.217C10.021 13.3666 10.0497 13.5164 10.1146 13.6517V13.6517Z"
              stroke="#333333"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M18.375 5.25H22.75V9.625"
              stroke="#333333"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M17.5 10.5L22.75 5.25"
              stroke="#333333"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          <p>
            Call us
            <span>+91-9314444747</span>
          </p>
        </Link>
        <Link
          href={'/offline-furniture-store'}
          className={`${styles['footer-list']} ${styles['store-list']}`}
        >
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.25 15.2675V22.75C5.25 22.9821 5.34219 23.2046 5.50628 23.3687C5.67038 23.5328 5.89294 23.625 6.125 23.625H21.875C22.1071 23.625 22.3296 23.5328 22.4937 23.3687C22.6578 23.2046 22.75 22.9821 22.75 22.75V15.2676"
              stroke="#333333"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M5.91001 4.375H22.09C22.2801 4.375 22.4651 4.43694 22.6169 4.55145C22.7687 4.66595 22.8791 4.82679 22.9313 5.00962L24.5 10.5H3.5L5.06868 5.00962C5.12092 4.82679 5.23129 4.66595 5.38309 4.55145C5.5349 4.43694 5.71987 4.375 5.91001 4.375Z"
              stroke="#333333"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M10.5 10.5V12.25C10.5 13.1783 10.1313 14.0685 9.47487 14.7249C8.8185 15.3813 7.92826 15.75 7 15.75C6.07174 15.75 5.1815 15.3813 4.52513 14.7249C3.86875 14.0685 3.5 13.1783 3.5 12.25V10.5"
              stroke="#333333"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M17.5 10.5V12.25C17.5 13.1783 17.1313 14.0685 16.4749 14.7249C15.8185 15.3813 14.9283 15.75 14 15.75C13.0717 15.75 12.1815 15.3813 11.5251 14.7249C10.8687 14.0685 10.5 13.1783 10.5 12.25V10.5"
              stroke="#333333"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M24.5 10.5V12.25C24.5 13.1783 24.1313 14.0685 23.4749 14.7249C22.8185 15.3813 21.9283 15.75 21 15.75C20.0717 15.75 19.1815 15.3813 18.5251 14.7249C17.8687 14.0685 17.5 13.1783 17.5 12.25V10.5"
              stroke="#333333"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          <p>
            100+ Stores
            <span>Get Extra 10% OFF*</span>
          </p>
        </Link>
      </section>
    </>
  );
};

export default CallUs;
